import DynamicSectionElement from '@/scripts/dynamic-section-element.js'
import debounce from 'lodash/debounce'

const NAVIGATION_SELECTOR = '.js-navigation'
const ANNOUNCEMENT_SELECTOR = '.js-announcement-bar'
const HIDE_HEADER_CLASS = 'is-scrolling-down'
const SEARCH_OPEN_CLASS = 'is-search-open'

class SiteHeader extends DynamicSectionElement {
  constructor () {
    super()

    this.body = document.body
    this.navigationEl = this.querySelector(NAVIGATION_SELECTOR)
    this.announcementBarEl = this.querySelector(ANNOUNCEMENT_SELECTOR)

    this.triggerNavScroll()
    this.getHeightEl()

    window.addEventListener('scroll', () => {
      this.triggerNavScroll()
    }, { passive: true })

    window.addEventListener('resize', debounce(() => {
      this.getHeightEl()
    }, 500))
  }

  getHeightEl () {
    const announcementBarElOffset = this.announcementBarEl ? this.announcementBarEl.offsetHeight : 0
    document.querySelector(':root').style.setProperty('--height-announcement', `${announcementBarElOffset}px`)
  }

  triggerNavScroll () {
    const st = window.pageYOffset || document.documentElement.scrollTop
    const offsetHeight = this.offsetHeight

    st > 0 ? this.body.classList.add(HIDE_HEADER_CLASS) : this.body.classList.remove(HIDE_HEADER_CLASS)
  }

  openDrawer (event) {
    if (event && event.preventDefault) {
      if (event.metaKey) {
        return
      }
      event.preventDefault()
    }

    this.classList.add('open-slideout')
    document.body.classList.add('overflow-hidden')
    document.body.classList.add('is-open-slideout')
  }

  toggleSearchBar () {
    const searchContainer = this.querySelector('[data-search]')

    if (searchContainer.classList.contains('active')) {
      searchContainer.classList.remove('active')
      document.body.classList.remove('overflow-hidden')
      document.body.classList.remove(SEARCH_OPEN_CLASS)
    } else {
      searchContainer.classList.add('active')
      document.body.classList.add('overflow-hidden')
      document.body.classList.add(SEARCH_OPEN_CLASS)
    }
  }
}

// Use base DynamicSectionElement class to enable replacing of content loaded from Section Rendering API
customElements.define('site-header', SiteHeader)
